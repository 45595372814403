<template>
  <div class="page more-list" @click.stop="">
    <div class="container">
      <div class="body">
        <ul class="list-section">
          <li
            v-for="(item, index) in data.items"
            :key="`${data.title}-item-${index}`"
            :class="{ active: item.active === true }"
          >
            <button type="button" v-button @click="item.action()" :class="{ 'link-button': item.link === true }">
              <span>{{ item.title | capitalizeFirstLetter }}</span>
              <img v-if="item.link === true" alt="arrow" src="/img/arrow-right@3x.png" />
            </button>
            <img v-if="item.active === true" alt="checked" src="/img/icon-checked.svg" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        title: this.$__t('약관 및 정책'),
        items: [
          {
            title: this.$__t('이용약관'),
            action: () => this.$router.push({ name: 'CsTermsOfUse' }).finally(() => this.$emit('close-sidebar')),
            link: true,
          },
          {
            title: this.$__t('개인정보 처리방침'),
            action: () => this.$router.push({ name: 'CsPrivacyPolicy' }).finally(() => this.$emit('close-sidebar')),
            link: true,
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.page {
  @include fill-viewport-min-height;
}
</style>
